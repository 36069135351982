import type { Env } from '../types/env';

export const environment: Env = {
  production: true,
  middleware: 'middleware-sg-prod/',
  api: 'https://apim.sg.dynatex.io/',
  cmsProxyTarget: 'https://cms.sg.dynatex.io/api/',
  log: false,
  useCmsProxy: false,
  VAPIDPublicKey: 'BPP9nbL6xlQPtsj8yNamPdIhgFm8S8RIoLMDoXoYRuoqef2PyJH-FIF8U-i14D06eZQrtG-P7GJDrGpUv7UiPGs',
  name: 'sg-prod',
};
